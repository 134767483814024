
export interface Article {
  number: number
  title: string
  labels: string[]
  createdAt: string
}

export const Articles: Article[] = [
  {
    number: 41,
    title: 'freepublicapis',
    labels: ['reference','draft'],
    createdAt: '2024-09-11T14:46:33Z',
  },
  {
    number: 40,
    title: 'Maybe I Should Make an API Monkey Someday',
    labels: ['self','draft'],
    createdAt: '2024-09-10T15:31:08Z',
  },
  {
    number: 39,
    title: '正確地使用 Header',
    labels: ['reference','draft'],
    createdAt: '2024-09-10T15:28:56Z',
  },
  {
    number: 38,
    title: '歐盟薪資透明法，預計於 2026 年 6 月全面實施',
    labels: ['news','draft'],
    createdAt: '2024-09-10T15:16:50Z',
  },
  {
    number: 37,
    title: '支撐 Netflix 運作的神祕硬體，Reddit 網友取得 262TB 快取伺服器',
    labels: ['news','draft'],
    createdAt: '2024-09-08T08:31:38Z',
  },
  {
    number: 36,
    title: 'sadservers.com',
    labels: ['reference','draft'],
    createdAt: '2024-09-08T08:30:29Z',
  },
  {
    number: 35,
    title: 'Event Driven Architecture - 5 Pitfalls to Avoid',
    labels: ['reference','draft'],
    createdAt: '2024-09-08T08:28:12Z',
  },
  {
    number: 34,
    title: 'Nginx Certbot Auto Setup',
    labels: ['reference','draft'],
    createdAt: '2024-09-08T08:26:50Z',
  },
  {
    number: 33,
    title: 'API First 的策略與架構',
    labels: ['reference','draft'],
    createdAt: '2024-09-08T08:25:34Z',
  },
  {
    number: 32,
    title: 'Liquibase vs Flyway',
    labels: ['reference','draft'],
    createdAt: '2024-09-08T08:24:16Z',
  },
  {
    number: 31,
    title: '給 Java 新手的一封信 (2022 版)',
    labels: ['reference','draft'],
    createdAt: '2024-09-08T08:23:19Z',
  },
  {
    number: 30,
    title: 'Full Stack Python',
    labels: ['news','draft'],
    createdAt: '2024-09-01T04:20:45Z',
  },
  {
    number: 29,
    title: '超長 GBoard',
    labels: ['meme','draft'],
    createdAt: '2024-09-01T04:19:50Z',
  },
  {
    number: 28,
    title: '工程師跨越管理的第一道牆 - 放下',
    labels: ['reference','draft'],
    createdAt: '2024-09-01T04:18:12Z',
  },
  {
    number: 27,
    title: 'Search Icons',
    labels: ['news','draft'],
    createdAt: '2024-09-01T04:17:39Z',
  },
  {
    number: 26,
    title: 'GitHub Copilot被告了',
    labels: ['news','draft'],
    createdAt: '2024-09-01T04:16:48Z',
  },
  {
    number: 25,
    title: '動物表情攝影大賽',
    labels: ['news'],
    createdAt: '2024-09-01T04:15:59Z',
  },
  {
    number: 24,
    title: 'Windows 要出 ARM 版本',
    labels: ['news'],
    createdAt: '2024-09-01T04:14:50Z',
  },
  {
    number: 23,
    title: '正確的 DevOps 總結',
    labels: ['reference','draft'],
    createdAt: '2024-09-01T04:13:04Z',
  },
  {
    number: 22,
    title: 'capturing http requests',
    labels: ['reference','draft'],
    createdAt: '2024-09-01T04:11:56Z',
  },
  {
    number: 21,
    title: 'snoo bassinet baby sleeping subscription',
    labels: ['news','draft'],
    createdAt: '2024-09-01T04:09:03Z',
  },
  {
    number: 20,
    title: 'why lowercase letters save data',
    labels: ['reference','draft'],
    createdAt: '2024-09-01T04:07:36Z',
  },
  {
    number: 19,
    title: 'How to Turn Your iPhone Screen Red',
    labels: ['reference'],
    createdAt: '2024-08-31T17:40:02Z',
  },
  {
    number: 18,
    title: 'DevToys',
    labels: ['self','draft'],
    createdAt: '2024-07-06T01:34:07Z',
  },
  {
    number: 17,
    title: 'Programmers are afraid of light IDE theme',
    labels: ['meme'],
    createdAt: '2024-06-16T09:23:58Z',
  },
  {
    number: 16,
    title: 'API First 的開發策略',
    labels: ['reference','draft'],
    createdAt: '2024-06-16T09:20:47Z',
  },
  {
    number: 15,
    title: 'Line TW 內部分享演講的投影片專區',
    labels: ['self','draft'],
    createdAt: '2024-06-16T08:48:25Z',
  },
  {
    number: 14,
    title: '分享自己常用的 JetBrains Plugins',
    labels: ['self','draft'],
    createdAt: '2024-06-16T08:47:17Z',
  },
  {
    number: 13,
    title: 'GitHub 個人頁的美化',
    labels: ['self'],
    createdAt: '2024-06-16T03:28:43Z',
  },
  {
    number: 12,
    title: '有夠淘氣',
    labels: ['self','draft'],
    createdAt: '2024-06-16T03:26:48Z',
  }
]
